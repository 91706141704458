import { Page } from '@komo-tech/core/models/Page';
import { useQuery } from '@tanstack/react-query';

import { PaginationProps, usePagination } from '@/common/components/Pagination';
import { FrontContactAvatar } from '@/common/models/contact-avatars/Front/FrontContactAvatar';
import { SearchAvatarsQuery } from '@/front/data/Contacts/_types';

import { PublicContactsActions } from '../_actions';
import { PublicContactsCacheKeys } from '../_cacheKeys';

interface Value {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  data: Page<FrontContactAvatar>;
  hasAnyData: boolean;
  hasData: boolean;
  error: unknown;
  pagination: PaginationProps;
}

export const useSearchAvatarsQuery = (
  pageSize: number = 10,
  disabled: boolean = false
): Value => {
  const pagination = usePagination(pageSize);
  const request: SearchAvatarsQuery = {
    skip: pagination.skip,
    take: pagination.take
  };

  const query = useQuery(
    PublicContactsCacheKeys.avatars.search(request),
    () => PublicContactsActions.avatars.searchAsync(request),
    {
      enabled: !disabled,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (page) =>
        pagination.setDefaults(page.items.length, page.totalItems),
      onError: (err) => console.error(err)
    }
  );

  return {
    hasAnyData: query.data?.totalItemsNoFilter > 0,
    hasData: !!query?.data?.items?.length,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    isError: query.isError,
    error: query.error,
    data: query.data,
    pagination
  };
};
