import { useQuery } from '@tanstack/react-query';

import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import { BlockContactPropertyDataSourceInstance } from '@/common/components/Contacts/BlockBuilder/DataSources/BlockContactPropertyDataSourceInstance';
import { Guid } from '@komo-tech/core/models/Guid';
import { PublicContactsService } from '@/front/data/Contacts';

export const useContactPropertyAvatarDataSource = (
  dataSource?: DataSourceInstance,
  propertyId?: Guid
) => {
  const contactPropertyDataSourceInstance =
    BlockContactPropertyDataSourceInstance.isImplementedBy(dataSource)
      ? dataSource
      : undefined;

  const contactProfileQuery = useQuery(
    PublicContactsService.cacheKeys.properties.values(),
    () => PublicContactsService.actions.properties.getValuesAsync(),
    {
      refetchOnMount: !contactPropertyDataSourceInstance?.isServerGenerated,
      enabled: contactPropertyDataSourceInstance?.isValid() && !!propertyId
    }
  );

  if (contactPropertyDataSourceInstance?.isPortal || !propertyId) {
    return {
      propertyAvatar: undefined,
      isLoading: false,
      isError: false
    };
  }

  const property = contactProfileQuery?.data?.propertyValues.find((x) =>
    propertyId?.equals(x.propertyDefinitionId)
  );

  const fallback = contactProfileQuery?.data?.defaultContactAvatar;

  return {
    propertyAvatar: property?.contactAvatar?.image ?? fallback?.image,
    isLoading: contactProfileQuery.isLoading,
    isError: contactProfileQuery.isError
  };
};
