import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useEvent } from '@komo-tech/core/hooks/useEvent';
import { Guid } from '@komo-tech/core/models/Guid';
import { sleepAsync } from '@komo-tech/core/utils/sleepAsync';

import { PublicContactsActions } from '../_actions';
import {
  ContactCompetitionEntryCheckRequest,
  ContactCompetitionEntryCheckResponse
} from '../_types';

interface Options {
  onSuccess?: (data: { email: string; existingEntryId?: Guid }) => void;
  onRequiresVerification?: (data: {
    email: string;
    emailSent?: boolean;
    existingEntryId: Guid;
  }) => void;
  delay?: number;
}

export function useContactCompetitionEntryCheckHandleAsync({
  onSuccess,
  onRequiresVerification,
  delay
}: Options) {
  const [handleAsync, { isHandling }] = useActionHandler();

  const handleCheckAsync = useEvent(
    (request: ContactCompetitionEntryCheckRequest) =>
      handleAsync(
        async () => {
          if (delay > 0) {
            await sleepAsync(delay);
          }
          return await PublicContactsActions.checkCompetitionEntryAsync(
            request
          );
        },
        {
          onSuccess: (r: ContactCompetitionEntryCheckResponse) => {
            if (r.success) {
              onSuccess?.({
                email: request.email,
                existingEntryId: r.existingEntryId
              });
            } else {
              onRequiresVerification?.({
                email: request.email,
                emailSent: r.emailSent,
                existingEntryId: r.existingEntryId
              });
            }
          },
          onError: console.error
        }
      )
  );

  return [handleCheckAsync, isHandling] as const;
}
