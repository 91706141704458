import { useQuery } from '@tanstack/react-query';

import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import { BlockContactPropertyDataSourceInstance } from '@/common/components/Contacts/BlockBuilder/DataSources/BlockContactPropertyDataSourceInstance';
import { Guid } from '@komo-tech/core/models/Guid';
import { PublicContactsService } from '@/front/data/Contacts';

export const useContactPropertyTextDataSource = (
  dataSource?: DataSourceInstance,
  propertyId?: Guid
) => {
  const contactPropertyDataSourceInstance =
    BlockContactPropertyDataSourceInstance.isImplementedBy(dataSource)
      ? dataSource
      : undefined;

  const contactProfileQuery = useQuery(
    PublicContactsService.cacheKeys.properties.values(),
    () => PublicContactsService.actions.properties.getValuesAsync(),
    {
      enabled: contactPropertyDataSourceInstance.isValid(),
      refetchOnMount: !contactPropertyDataSourceInstance?.isServerGenerated,
      refetchOnWindowFocus: false
    }
  );

  const property = contactProfileQuery?.data?.propertyValues.find((x) =>
    propertyId?.equals(x.propertyDefinitionId)
  );

  return {
    propertyText: property?.displayValue ?? '',
    isLoading: contactProfileQuery?.isLoading,
    isError: contactProfileQuery?.isError
  };
};
